import {
  setHours,
  setMinutes,
  setSeconds,
  isToday,
  format,
  getDayOfYear,
} from 'date-fns'

// ' ': observed (space)
// M: missing
// C: calculated
// I: interpolated
// U: from URMA data
// F for from NDFD forecast data.

export default function allWeatherDataQueryLogic(
  data,
  startDate,
  endDate,
  allParams,
) {
  const {hourlyData, hourlyFcstData, hrlyFields} = data

  // Hourly Data ===========================================================
  let hourlyEndDateIdx = hourlyData.length
  if (!isToday(endDate)) {
    const endDate23 = setHours(endDate, 23)
    const endDate2300 = setMinutes(endDate23, 0)
    const endDate230000 = setSeconds(endDate2300, 0)
    const hdeIdx = hourlyData.findIndex(d => d.ms === endDate230000.getTime())
    if (hdeIdx !== -1) hourlyEndDateIdx = hdeIdx + 1
  }

  let hourlyStartDateIdx = 0
  if (startDate && getDayOfYear(startDate) > 15) {
    const yy = startDate.getFullYear()
    const mm = startDate.getMonth()
    const dd = startDate.getDate()
    const hdsIdx = hourlyData.findIndex(
      d => d.ms === new Date(yy, mm, dd).getTime(),
    )
    if (hdsIdx !== -1) hourlyStartDateIdx = hdsIdx
  }

  const params = allParams
  const hourlyDataTransformed = hourlyData
    .slice(hourlyStartDateIdx, hourlyEndDateIdx)
    .map(d => {
      let p = {
        date: format(d.date, 'MM/dd/yyyy HH:00'),
      }
      params.forEach(param => {
        const index = hrlyFields.slice(2).indexOf(param)
        if (param in d) {
          p[param] = d[param]
          p[`${param}Flag`] = d.flags[index]
        }
      })
      return p
    })

  const hourlyFcstDataTransformed = hourlyFcstData.map(d => {
    let p = {
      date: format(d.date, 'MM/dd/yyyy HH:00'),
    }
    params.forEach(param => {
      const index = hrlyFields.slice(2).indexOf(param)
      if (param in d) {
        p[param] = d[param]
        p[`${param}Flag`] = d.flags[index]
      }
    })
    return p
  })

  let p = {}
  Object.keys(hourlyDataTransformed[0]).forEach(k => (p[k] = []))
  let dailyData = []
  let allData = [...hourlyDataTransformed]
  if (isToday(endDate)) {
    allData = [...hourlyDataTransformed, ...hourlyFcstDataTransformed]
  }
  const firstHourAvailable = Number(hourlyDataTransformed[0].date.slice(11, 13))

  allData.forEach((h, i) => {
    const hour = Number(h.date.slice(11, 13))
    Object.keys(p).forEach(k => (p[k] = [...p[k], h[k]]))

    if (i === 23 - firstHourAvailable) {
      // first day
      dailyData.push({...p})
      Object.keys(p).forEach(k => (p[k] = []))
    }
    if (i > 23 - firstHourAvailable) {
      // all other days
      if (hour === 23) {
        dailyData.push({...p})
        Object.keys(p).forEach(k => (p[k] = []))
      }
    }
  })

  const dailyKeys = Object.keys(dailyData[0])
  const dailyDataSummary = dailyData.map(d => {
    // temp
    let isTemp = false
    let tempMissVal = null
    let tempHasEstimatedVal = null
    let avgT = 'N/A'
    let maxT = 'N/A'
    let minT = 'N/A'
    if (dailyKeys.includes('temp')) {
      isTemp = true
      tempMissVal = d.temp.filter(d => d === 'M').length
      tempHasEstimatedVal = d.tempFlag.filter(d => d !== ' ').length
      const temps = d.temp.filter(d => typeof d === 'number')
      if (temps.length !== 0) {
        // avgT = Number(
        //   (temps.reduce((acc, val) => acc + val, 0) / temps.length).toFixed(1),
        // )
        maxT = Math.max(...temps)
        minT = Math.min(...temps)
        avgT = ((maxT + minT) / 2).toFixed(1)
      }
    }

    // prcp
    let isPrcp = false
    let prcpMissVal = null
    let prcpHasEstimatedVal = null
    let prcpCumulative = 'N/A'
    if (dailyKeys.includes('prcp')) {
      isPrcp = true
      prcpMissVal = d.prcp.filter(d => d === 'M').length
      prcpHasEstimatedVal = d.prcpFlag.filter(d => d !== ' ').length
      const prcps = d.prcp.filter(d => typeof d === 'number')
      if (prcps.length !== 0)
        prcpCumulative = Number(
          prcps.reduce((acc, val) => acc + val, 0).toFixed(2),
        )
    }

    // lwet
    let isLwet = false
    let lwetMissVal = null
    let lwetHasEstimatedVal = null
    let lwetHours = 'N/A'
    if (dailyKeys.includes('lwet')) {
      isLwet = true
      lwetMissVal = d.lwet.filter(d => d === 'M').length
      lwetHasEstimatedVal = d.lwetFlag.filter(d => d !== ' ').length
      const lwets = d.lwet.filter(d => typeof d === 'number' && d > 0)
      lwetHours = 0
      if (lwets.length !== 0) lwetHours = lwets.length
    }

    // rhum
    let isRhum = false
    let rhumMissVal = null
    let rhumHasEstimatedVal = null
    let rhumHours = 'N/A'
    if (dailyKeys.includes('rhum')) {
      isRhum = true
      rhumMissVal = d.rhum.filter(d => d === 'M').length
      rhumHasEstimatedVal = d.rhumFlag.filter(d => d !== ' ').length
      const rhums = d.rhum.filter(d => typeof d === 'number' && d >= 90)
      rhumHours = 0
      if (rhums.length !== 0) rhumHours = rhums.length
    }

    // srad
    let isSrad = false
    let sradMissVal = null
    let sradHasEstimatedVal = null
    let srad = 'N/A'
    if (dailyKeys.includes('srad')) {
      isSrad = true
      sradMissVal = d.srad.filter(d => d === 'M').length
      sradHasEstimatedVal = d.sradFlag.filter(d => d !== ' ').length
      const srads = d.srad.filter(d => typeof d === 'number')
      if (srads.length !== 0) srad = srads.reduce((acc, val) => acc + val, 0)
    }

    // wspd
    let isWspd = false
    let wspdMissVal = null
    let wspdHasEstimatedVal = null
    let avgWspd = 'N/A'
    if (dailyKeys.includes('wspd')) {
      isWspd = true
      wspdMissVal = d.wspd.filter(d => d === 'M').length
      wspdHasEstimatedVal = d.wspdFlag.filter(d => d !== ' ').length
      const wspds = d.wspd.filter(d => typeof d === 'number')
      if (wspds.length !== 0)
        avgWspd = Number(
          (wspds.reduce((acc, val) => acc + val, 0) / wspds.length).toFixed(1),
        )
    }

    // tmps - Soil Temperature ˚F
    let isTmps = false
    let tmpsMissVal = null
    let tmpsHasEstimatedVal = null
    let avgTmps = 'N/A'
    if (dailyKeys.includes('tmps')) {
      isTmps = true
      tmpsMissVal = d.tmps.filter(d => d === 'M').length
      tmpsHasEstimatedVal = d.tmpsFlag.filter(d => d !== ' ').length
      const tmps = d.tmps.filter(d => typeof d === 'number')
      if (tmps.length !== 0)
        avgTmps = Number(
          (tmps.reduce((acc, val) => acc + val, 0) / tmps.length).toFixed(1),
        )
    }

    // mois - Soil moisture m3/m3
    let isMois = false
    let moisMissVal = null
    let moisHasEstimatedVal = null
    let avgMois = 'N/A'
    if (dailyKeys.includes('mois')) {
      isMois = true
      moisMissVal = d.mois.filter(d => d === 'M').length
      moisHasEstimatedVal = d.moisFlag.filter(d => d !== ' ').length
      const moiss = d.mois.filter(d => typeof d === 'number')
      if (moiss.length !== 0)
        avgMois = Number(
          (moiss.reduce((acc, val) => acc + val, 0) / moiss.length).toFixed(1),
        )
    }

    // tens - Soil tension kPa
    let isTens = false
    let tensMissVal = null
    let tensHasEstimatedVal = null
    let avgTens = 'N/A'
    if (dailyKeys.includes('tens')) {
      isTens = true
      tensMissVal = d.tens.filter(d => d === 'M').length
      tensHasEstimatedVal = d.tensFlag.filter(d => d !== ' ').length
      const tenss = d.tens.filter(d => typeof d === 'number')
      if (tenss.length !== 0)
        avgTens = Number(
          (tenss.reduce((acc, val) => acc + val, 0) / tenss.length).toFixed(1),
        )
    }

    return {
      date: d.date.slice(-1)[0],
      isTemp,
      avgT,
      maxT,
      minT,
      tempMissVal,
      tempHasEstimatedVal,
      isPrcp,
      prcpCumulative,
      prcpMissVal,
      prcpHasEstimatedVal,
      isLwet,
      lwetHours,
      lwetMissVal,
      lwetHasEstimatedVal,
      isRhum,
      rhumHours,
      rhumMissVal,
      rhumHasEstimatedVal,
      isSrad,
      srad,
      sradMissVal,
      sradHasEstimatedVal,
      isWspd,
      avgWspd,
      wspdMissVal,
      wspdHasEstimatedVal,
      isTmps,
      avgTmps,
      tmpsMissVal,
      tmpsHasEstimatedVal,
      isMois,
      avgMois,
      moisMissVal,
      moisHasEstimatedVal,
      isTens,
      tensMissVal,
      tensHasEstimatedVal,
      avgTens,
    }
  })

  let dailyDataSummaryFinal = [...dailyDataSummary]
  if (isToday(endDate)) {
    dailyDataSummaryFinal = dailyDataSummaryFinal.slice(0, -5)
  }

  // Summary -------------------------------------------------------------
  const firstDataPoint = dailyDataSummaryFinal[0]

  const isTemp = firstDataPoint.isTemp
  let summaryAvgT = null
  let summaryAvgTMin = null
  let summaryAvgTMax = null
  if (firstDataPoint.isTemp) {
    const avgTs = dailyDataSummaryFinal
      .filter(d => d !== 'N/A')
      .map(d => +d.avgT)

    if (avgTs.length !== 0) {
      summaryAvgTMin = Math.min(...avgTs)
      summaryAvgTMax = Math.max(...avgTs)

      summaryAvgT = (
        avgTs.reduce((acc, val) => acc + val, 0) / avgTs.length
      ).toFixed(1)
    }
  }

  let summaryMaxT = null
  let summaryMaxTAvg = null
  let summaryMaxTMin = null
  if (firstDataPoint.isTemp) {
    const maxTs = dailyDataSummaryFinal
      .filter(d => d !== 'N/A')
      .map(d => d.maxT)
    if (maxTs.length !== 0) {
      summaryMaxT = Math.max(...maxTs)
      summaryMaxTMin = Math.min(...maxTs)
      summaryMaxTAvg = (
        maxTs.reduce((acc, val) => acc + val, 0) / maxTs.length
      ).toFixed(1)
    }
  }

  let summaryMinT = null
  let summaryMinTAvg = null
  let summaryMinTMax = null
  if (firstDataPoint.isTemp) {
    const minTs = dailyDataSummaryFinal
      .filter(d => d !== 'N/A')
      .map(d => d.minT)
    if (minTs.length !== 0) {
      summaryMinT = Math.min(...minTs)
      summaryMinTMax = Math.max(...minTs)
      summaryMinTAvg = (
        minTs.reduce((acc, val) => acc + val, 0) / minTs.length
      ).toFixed(1)
    }
  }

  const isPrcp = firstDataPoint.isPrcp
  let summaryPrcp = null
  let summaryPrcpMin = null
  let summaryPrcpMax = null
  if (firstDataPoint.isPrcp) {
    const prcps = dailyDataSummaryFinal
      .map(d => d.prcpCumulative)
      .filter(d => d !== 'N/A')
    if (prcps.length !== 0) {
      summaryPrcp = prcps.reduce((acc, val) => acc + val, 0).toFixed(2)
      summaryPrcpMin = Math.min(...prcps)
      summaryPrcpMax = Math.max(...prcps)
    }
  }

  const isLwet = firstDataPoint.isLwet
  let summaryLwet = null
  let summaryLwetMin = null
  let summaryLwetMax = null
  if (firstDataPoint.isLwet) {
    const lwets = dailyDataSummaryFinal
      .map(d => d.lwetHours)
      .filter(d => d !== 'N/A')
    if (lwets.length !== 0) {
      summaryLwet = lwets.reduce((acc, val) => acc + val, 0)
      summaryLwetMin = Math.min(...lwets)
      summaryLwetMax = Math.max(...lwets)
    }
  }

  const isRhum = firstDataPoint.isRhum
  let summaryRhum = null
  let summaryRhumMax = null
  let summaryRhumMin = null
  if (firstDataPoint.isRhum) {
    const rhums = dailyDataSummaryFinal
      .map(d => d.rhumHours)
      .filter(d => d !== 'N/A')
    if (rhums.length !== 0) {
      summaryRhum = rhums.reduce((acc, val) => acc + val, 0)
      summaryRhumMax = Math.max(...rhums)
      summaryRhumMin = Math.min(...rhums)
    }
  }

  const isSrad = firstDataPoint.isSrad
  let summarySrad = null
  let summarySradMax = null
  let summarySradMin = null
  if (firstDataPoint.isSrad) {
    const srads = dailyDataSummaryFinal
      .map(d => d.srad)
      .filter(d => d !== 'N/A')
    if (srads.length !== 0) {
      summarySrad = srads.reduce((acc, val) => acc + val, 0)
      summarySradMax = Math.max(...srads)
      summarySradMin = Math.min(...srads)
    }
  }

  const isWspd = firstDataPoint.isWspd
  let summaryWspd = null
  let summaryWspdMax = null
  let summaryWspdMin = null
  if (firstDataPoint.isWspd) {
    const avgWspds = dailyDataSummaryFinal
      .map(d => d.avgWspd)
      .filter(d => d !== 'N/A')
    if (avgWspds.length !== 0) {
      summaryWspd = (
        avgWspds.reduce((acc, val) => acc + val, 0) / avgWspds.length
      ).toFixed(1)
      summaryWspdMax = Math.max(...avgWspds)
      summaryWspdMin = Math.min(...avgWspds)
    }
  }

  const isTmps = firstDataPoint.isTmps
  let summaryTmps = null
  let summaryTmpsMax = null
  let summaryTmpsMin = null
  if (firstDataPoint.isTmps) {
    const avgTmps = dailyDataSummaryFinal
      .filter(d => d !== 'N/A')
      .map(d => +d.avgTmps)
    if (avgTmps.length !== 0) {
      summaryTmps = (
        avgTmps.reduce((acc, val) => acc + val, 0) / avgTmps.length
      ).toFixed(1)
      summaryTmpsMax = Math.max(...avgTmps)
      summaryTmpsMin = Math.min(...avgTmps)
    }
  }

  const isMois = firstDataPoint.isMois
  let summaryMois = null
  let summaryMoisMax = null
  let summaryMoisMin = null
  if (firstDataPoint.isMois) {
    const avgMois = dailyDataSummaryFinal
      .filter(d => d !== 'N/A')
      .map(d => +d.avgMois)
    if (avgMois.length !== 0) {
      summaryMois = (
        avgMois.reduce((acc, val) => acc + val, 0) / avgMois.length
      ).toFixed(1)
      summaryMoisMax = Math.max(...avgMois)
      summaryMoisMin = Math.min(...avgMois)
    }
  }

  const isTens = firstDataPoint.isTens
  let summaryTens = null
  let summaryTensMax = null
  let summaryTensMin = null
  if (firstDataPoint.isTens) {
    const avgTens = dailyDataSummaryFinal
      .filter(d => d !== 'N/A')
      .map(d => +d.avgTens)
    if (avgTens.length !== 0) {
      summaryTens = (
        avgTens.reduce((acc, val) => acc + val, 0) / avgTens.length
      ).toFixed(1)
      summaryTensMax = Math.max(...avgTens)
      summaryTensMin = Math.min(...avgTens)
    }
  }

  const summary = {
    isTemp,
    summaryAvgT,
    summaryAvgTMin,
    summaryAvgTMax,
    summaryMaxT,
    summaryMaxTAvg,
    summaryMaxTMin,
    summaryMinT,
    summaryMinTAvg,
    summaryMinTMax,
    isPrcp,
    summaryPrcp,
    summaryPrcpMin,
    summaryPrcpMax,
    isLwet,
    summaryLwet,
    summaryLwetMin,
    summaryLwetMax,
    isRhum,
    summaryRhum,
    summaryRhumMax,
    summaryRhumMin,
    isSrad,
    summarySrad,
    summarySradMax,
    summarySradMin,
    isWspd,
    summaryWspd,
    summaryWspdMax,
    summaryWspdMin,
    isTmps,
    summaryTmps,
    summaryTmpsMax,
    summaryTmpsMin,
    isMois,
    summaryMois,
    summaryMoisMax,
    summaryMoisMin,
    isTens,
    summaryTens,
    summaryTensMax,
    summaryTensMin,
  }

  return {
    dailyDataSummary: dailyDataSummaryFinal,
    hourlyData: hourlyDataTransformed,
    summary,
  }
}

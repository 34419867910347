import React from 'react'
import { CSVLink } from 'react-csv'
import ScrollableFeed from 'react-scrollable-feed'

const td = `text-xs sm:text-sm px-6 py-4 text-gray-700`
const miss = `flex items-center justify-center w-5 h-5 mx-auto font-semibold text-white bg-red-500 rounded-full text-xs`

export default function HourlyDataTable({
  data,
  modelData,
  station,
  tableHeaders,
}) {
  if (!data) return null

  const csvData = data.map((d, i) => {
    let p = { date: d.date }
    if ('temp' in d) p['Air Temp ˚F'] = d.temp
    if ('dwpt' in d) p['Dew Point ˚F'] = d.dwpt
    if ('prcp' in d) p['Precipitation (inches)'] = d.prcp
    if ('rhum' in d) p['Relative Humidity (%)'] = d.rhum
    if ('lwet' in d) p['Leaf Wetness (minutes)'] = d.lwet
    if ('srad' in d) p['Solar Radiation (langleys)'] = d.srad
    if ('wspd' in d) p['Wind Speed (mph)'] = d.wspd
    if ('wdir' in d) p['Wind Direction (degrees)'] = d.wdir
    if ('tmps' in d) p['Soil Temp (˚F)'] = d.tmps
    if ('mois' in d) p['Soil Moisture (m3/m3)'] = d.mois
    if ('tens' in d) p['Soil Tension (kPa)'] = d.tens
    return p
  })

  const csvFile = `Hourly Data for ${station.name}, ${station.state} - [id=${station.id}, lat=${station.lat}, lon=${station.lon}].csv`

  return (
    <div className="w-full">
      <div className="flex items-center justify-between mb-3">
        <h2 className="text-xl font-semibold text-gray-600 md:text-2xl">
          {modelData.title}
        </h2>

        <div className="flex justify-center rounded-md shadow-sm">
          <button
            type="button"
            aria-label="download results table data in csv format"
            className="inline-flex items-center p-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out border border-transparent rounded-md sm:px-3 sm:py-2 bg-secondary-800 hover:bg-secondary-700 focus:outline-none focus:border-secondary-900 focus:shadow-outline-secondary active:bg-secondary-900"
          >
            <svg
              className="-ml-0.5 mr-2 h-4 w-4"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
            </svg>

            <CSVLink
              className="text-white no-underline"
              filename={csvFile}
              data={csvData}
            >
              <span className="hidden text-white sm:inline-block">
                Download
              </span>{' '}
              <span className="text-white">CSV</span>
            </CSVLink>
          </button>
        </div>
      </div>

      <div className="flex flex-col my-4 sm:flex-row sm:justify-between sm:items-center">
        <div className="flex items-center">
          <span className="inline-block py-2 mr-4">
            <a
              className="text-xs sm:text-sm"
              href={`http://forecast.weather.gov/MapClick.php?textField1=${station.lat}&textField2=${station.lon}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Forecast Details
            </a>
          </span>
        </div>
        <div className="flex flex-col mt-2 text-sm lg:mt-0 lg:items-center lg:flex-row">
          <div className="flex items-center space-x-4">
            <span className={'cell level-2'}>Estimated</span>
            <div className="flex space-x-1">
              <span className={`${miss}`}> M </span>
              <span> = Missing</span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-4">
        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="inline-block min-w-full overflow-y-auto align-middle border-b border-gray-200 shadow h-256 sm:rounded-lg">
            <ScrollableFeed>
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="sticky top-0 px-6 py-3 text-xs leading-4 tracking-wider text-center text-white border-r border-gray-200 bg-secondary-800">
                      <span className="block">Date</span>
                      <span className="block mt-1">(Local Time)</span>
                    </th>

                    {tableHeaders.map((el) => (
                      <th
                        key={el.name}
                        className="sticky top-0 px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800"
                      >
                        <span className="block">{el.name}</span>
                        <span className="block mt-1">({el.unit})</span>
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody className="bg-white">
                  {data.map((day, i) => {
                    return (
                      <tr
                        key={i}
                        className={`text-center ${i % 2 === 0 && `bg-gray-50`}`}
                      >
                        <td className={`${td}`}>{day.date}</td>

                        {'temp' in day && (
                          <td className={td}>
                            {day.temp === 'M' ? (
                              <span className={miss}>{day.temp}</span>
                            ) : day.tempFlag === ' ' ? (
                              day.temp
                            ) : (
                              <span className={'cell level-2'}>{day.temp}</span>
                            )}
                          </td>
                        )}

                        {'dwpt' in day && (
                          <td className={td}>
                            {day.dwpt === 'M' ? (
                              <span className={miss}>{day.dwpt}</span>
                            ) : day.dwptFlag === ' ' ? (
                              day.dwpt
                            ) : (
                              <span className={'cell level-2'}>{day.dwpt}</span>
                            )}
                          </td>
                        )}

                        {'prcp' in day && (
                          <td className={td}>
                            {day.prcp === 'M' ? (
                              <span className={miss}>{day.prcp}</span>
                            ) : day.prcpFlag === ' ' ? (
                              day.prcp
                            ) : (
                              <span className={'cell level-2'}>{day.prcp}</span>
                            )}
                          </td>
                        )}

                        {'rhum' in day && (
                          <td className={td}>
                            {day.rhum === 'M' ? (
                              <span className={miss}>{day.rhum}</span>
                            ) : day.rhumFlag === ' ' ? (
                              day.rhum
                            ) : (
                              <span className={'cell level-2'}>{day.rhum}</span>
                            )}
                          </td>
                        )}

                        {'lwet' in day && (
                          <td className={td}>
                            {day.lwet === 'M' ? (
                              <span className={miss}>{day.lwet}</span>
                            ) : day.lwetFlag === ' ' ? (
                              day.lwet
                            ) : (
                              <span className={'cell level-2'}>{day.lwet}</span>
                            )}
                          </td>
                        )}

                        {'srad' in day && (
                          <td className={td}>
                            {day.srad === 'M' ? (
                              <span className={miss}>{day.srad}</span>
                            ) : day.sradFlag === ' ' ? (
                              day.srad
                            ) : (
                              <span className={'cell level-2'}>{day.srad}</span>
                            )}
                          </td>
                        )}

                        {'wspd' in day && (
                          <td className={td}>
                            {day.wspd === 'M' ? (
                              <span className={miss}>{day.wspd}</span>
                            ) : day.wspdFlag === ' ' ? (
                              day.wspd
                            ) : (
                              <span className={'cell level-2'}>{day.wspd}</span>
                            )}
                          </td>
                        )}

                        {'wdir' in day && (
                          <td className={td}>
                            {day.wdir === 'M' ? (
                              <span className={miss}>{day.wdir}</span>
                            ) : day.wdirFlag === ' ' ? (
                              day.wdir
                            ) : (
                              <span className={'cell level-2'}>{day.wdir}</span>
                            )}
                          </td>
                        )}

                        {'tmps' in day && (
                          <td className={td}>
                            {day.tmps === 'M' ? (
                              <span className={miss}>{day.tmps}</span>
                            ) : (
                              <span>{day.tmps}</span>
                            )}
                          </td>
                        )}

                        {'mois' in day && (
                          <td className={td}>
                            {day.mois === 'M' ? (
                              <span className={miss}>{day.mois}</span>
                            ) : (
                              <span>{day.mois}</span>
                            )}
                          </td>
                        )}

                        {'tens' in day && (
                          <td className={td}>
                            {day.tens === 'M' ? (
                              <span className={miss}>{day.tens}</span>
                            ) : (
                              <span>{day.tens}</span>
                            )}
                          </td>
                        )}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </ScrollableFeed>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import { CSVLink } from 'react-csv'
import ScrollableFeed from 'react-scrollable-feed'

const td = `text-xs sm:text-sm px-6 py-4 text-gray-700`
const tdSummary = `px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800 font-bold`

export default function DailySummaryTable({
  data,
  station,
  modelData,
  summary,
}) {
  if (!data) return null

  const csvData = data.map((d) => {
    const p = { date: d.date.slice(0, 10) }
    if (d.isTemp) {
      p['Avg Air Temp (˚F)'] = d.avgT
      p['Max Air Temp (˚F)'] = d.maxT
      p['Min Air Temp (˚F)'] = d.minT
    }
    if (d.isPrcp) p['Total Precipitation'] = d.prcpCumulative
    if (d.isLwet) p['Leaf Wethess (hrs)'] = d.lwetHours
    if (d.isRhum) p['Relative Humidity (hrs >= 90)'] = d.rhumHours
    if (d.isSrad) p['Solar Radiation (langleys)'] = d.srad
    if (d.isWspd) p['Avg Wind Speed (mph)'] = d.avgWspd
    if (d.isTmps) p['Avg Soil Temp (˚F)'] = d.avgTmps
    if (d.isTens) p['Avg Soil Tension (kPa)'] = d.avgTens
    if (d.isMois) p['Avg Soil Moisture (m3/m3)'] = d.avgMois

    return p
  })

  const csvFile = `Daily Data for ${station.name}, ${station.state} - [id=${station.id}, lat=${station.lat}, lon=${station.lon}].csv`

  return (
    <div className="w-full">
      <div className="flex items-center justify-between mb-3">
        <h2 className="text-xl font-semibold text-gray-600 md:text-2xl">
          {modelData.title}
        </h2>

        <div className="flex justify-center rounded-md shadow-sm">
          <button
            type="button"
            aria-label="download results table data in csv format"
            className="inline-flex items-center p-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out border border-transparent rounded-md sm:px-3 sm:py-2 bg-secondary-800 hover:bg-secondary-700 focus:outline-none focus:border-secondary-900 focus:shadow-outline-secondary active:bg-secondary-900"
          >
            <svg
              className="-ml-0.5 mr-2 h-4 w-4"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
            </svg>

            <CSVLink
              className="text-white no-underline"
              filename={csvFile}
              data={csvData}
            >
              <span className="hidden text-white sm:inline-block">
                Download
              </span>{' '}
              <span className="text-white">CSV</span>
            </CSVLink>
          </button>
        </div>
      </div>

      <div className="flex flex-col my-4 sm:flex-row sm:items-center sm:justify-end">
        <div className="flex flex-col mt-2 text-sm lg:mt-0 lg:items-center lg:flex-row">
          <div className="flex items-center space-x-4">
            <span className={'cell level-2'}>Estimated Data</span>
            <span className={'cell level-4'}>Missing data</span>
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-4">
        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="inline-block min-w-full overflow-y-auto align-middle border-b border-gray-200 shadow h-128 sm:rounded-lg">
            <ScrollableFeed>
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="sticky top-0 px-6 py-3 text-xs leading-4 tracking-wider text-center text-white border-r border-gray-200 bg-secondary-800">
                      <span className="block">Date</span>
                      <span className="block mt-1">(Local Time)</span>
                    </th>

                    <th className="sticky top-0 px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                      <span className="block">Avg Air Temp</span>
                      <span className="block mt-1">(˚F)</span>
                    </th>

                    <th className="sticky top-0 px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                      <span className="block">Max Air Temp</span>
                      <span className="block mt-1">(˚F)</span>
                    </th>

                    <th className="sticky top-0 px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                      <span className="block">Min Air Temp</span>
                      <span className="block mt-1">(˚F)</span>
                    </th>

                    {summary.isDwpt && (
                      <th className="sticky top-0 px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                        <span className="block">Dewpoint</span>
                        <span className="block mt-1">(˚F)</span>
                      </th>
                    )}

                    {summary.isPrcp && (
                      <th className="sticky top-0 px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                        <span className="block">Total Precip</span>
                        <span className="block mt-1">(Inches)</span>
                      </th>
                    )}

                    {summary.isLwet && (
                      <th className="sticky top-0 px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                        <span className="block">Leaf Wetness</span>
                        <span className="block mt-1">(Hours)</span>
                      </th>
                    )}

                    {summary.isRhum && (
                      <th className="sticky top-0 px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                        <span className="block">RH Hrs</span>
                        <span className="block mt-1">(&#8805; 90)</span>
                      </th>
                    )}
                    {summary.isSrad && (
                      <th className="sticky top-0 px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                        <span className="block">Solar Rad</span>
                        <span className="block mt-1">(langleys)</span>
                      </th>
                    )}

                    {summary.isWspd && (
                      <th className="sticky top-0 px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                        <span className="block">Avg Wind Spd</span>
                        <span className="block mt-1">(mph)</span>
                      </th>
                    )}

                    {summary.isTmps && (
                      <th className="sticky top-0 px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                        <span className="block">Avg Soil Temp</span>
                        <span className="block mt-1">(˚F)</span>
                      </th>
                    )}

                    {summary.isMois && (
                      <th className="sticky top-0 px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                        <span className="block">Avg Soil Moisture</span>
                        <span className="block mt-1">(m3/m3)</span>
                      </th>
                    )}

                    {summary.isTens && (
                      <th className="sticky top-0 px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                        <span className="block">Avg Soil Tension</span>
                        <span className="block mt-1">(kPa)</span>
                      </th>
                    )}
                  </tr>
                </thead>

                <tbody className="bg-white">
                  {data.map((day, i) => {
                    return (
                      <tr
                        key={day.date}
                        className={`text-center ${i % 2 === 0 && `bg-gray-50`}`}
                      >
                        {/* date */}
                        <td className={td}>{day.date.slice(0, 10)}</td>

                        {/* avgT */}
                        <td className={td}>
                          {day.tempHasEstimatedVal > 0 && (
                            <span className={'cell level-2'}>{day.avgT}</span>
                          )}
                          {day.tempMissVal > 0 &&
                            day.tempHasEstimatedVal === 0 && (
                              <span className={'cell level-4'}>{day.avgT}</span>
                            )}
                          {day.tempHasEstimatedVal === 0 &&
                            day.tempMissVal === 0 && <span>{day.avgT}</span>}
                        </td>

                        {/* maxT */}
                        <td className={td}>
                          {day.tempHasEstimatedVal > 0 && (
                            <span className={'cell level-2'}>{day.maxT}</span>
                          )}
                          {day.tempMissVal > 0 &&
                            day.tempHasEstimatedVal === 0 && (
                              <span className={'cell level-4'}>{day.maxT}</span>
                            )}
                          {day.tempHasEstimatedVal === 0 &&
                            day.tempMissVal === 0 && <span>{day.maxT}</span>}
                        </td>

                        {/* mint */}
                        <td className={td}>
                          {day.tempHasEstimatedVal > 0 && (
                            <span className={'cell level-2'}>{day.minT}</span>
                          )}
                          {day.tempMissVal > 0 &&
                            day.tempHasEstimatedVal === 0 && (
                              <span className={'cell level-4'}>{day.minT}</span>
                            )}
                          {day.tempHasEstimatedVal === 0 &&
                            day.tempMissVal === 0 && <span>{day.minT}</span>}
                        </td>

                        {/* prcpCumulative */}
                        {summary.isPrcp && (
                          <td className={td}>
                            {day.prcpHasEstimatedVal > 0 && (
                              <span className={'cell level-2'}>
                                {day.prcpCumulative}
                              </span>
                            )}
                            {day.prcpMissVal > 0 &&
                              day.prcpHasEstimatedVal === 0 && (
                                <span className={'cell level-4'}>
                                  {day.prcpCumulative}
                                </span>
                              )}
                            {day.prcpHasEstimatedVal === 0 &&
                              day.prcpMissVal === 0 && (
                                <span>{day.prcpCumulative}</span>
                              )}
                          </td>
                        )}

                        {/* lwet - Leaf Wetness */}
                        {summary.isLwet && (
                          <td className={td}>
                            {day.lwetHasEstimatedVal > 0 && (
                              <span className={'cell level-2'}>
                                {day.lwetHours}
                              </span>
                            )}
                            {day.lwetMissVal > 0 &&
                              day.lwetHasEstimatedVal === 0 && (
                                <span className={'cell level-4'}>
                                  {day.lwetHours}
                                </span>
                              )}
                            {day.lwetHasEstimatedVal === 0 &&
                              day.lwetMissVal === 0 && (
                                <span>{day.lwetHours}</span>
                              )}
                          </td>
                        )}

                        {/* rhum - Relative Humidity */}
                        {summary.isRhum && (
                          <td className={td}>
                            {day.rhumHasEstimatedVal > 0 && (
                              <span className={'cell level-2'}>
                                {day.rhumHours}
                              </span>
                            )}
                            {day.rhumMissVal > 0 &&
                              day.rhumHasEstimatedVal === 0 && (
                                <span className={'cell level-4'}>
                                  {day.rhumHours}
                                </span>
                              )}

                            {day.rhumHasEstimatedVal === 0 &&
                              day.rhumMissVal === 0 && (
                                <span>{day.rhumHours}</span>
                              )}
                          </td>
                        )}

                        {/* srad - Solar Radiation */}
                        {summary.isSrad && (
                          <td className={td}>
                            {day.sradHasEstimatedVal > 0 && (
                              <span className={'cell level-2'}>{day.srad}</span>
                            )}
                            {day.sradMissVal > 0 &&
                              day.sradHasEstimatedVal === 0 && (
                                <span className={'cell level-4'}>
                                  {day.srad}
                                </span>
                              )}
                            {day.sradHasEstimatedVal === 0 &&
                              day.sradMissVal === 0 && <span>{day.srad}</span>}
                          </td>
                        )}

                        {/* wspd - Wind Speed */}
                        {summary.isWspd && (
                          <td className={td}>
                            {day.wspdHasEstimatedVal > 0 && (
                              <span className={'cell level-2'}>
                                {day.avgWspd}
                              </span>
                            )}
                            {day.wspdMissVal > 0 &&
                              day.wspdHasEstimatedVal === 0 && (
                                <span className={'cell level-4'}>
                                  {day.avgWspd}
                                </span>
                              )}
                            {day.wspdHasEstimatedVal === 0 &&
                              day.wspdMissVal === 0 && (
                                <span>{day.avgWspd}</span>
                              )}
                          </td>
                        )}

                        {/* tmps - Soil Temperature */}
                        {summary.isTmps && (
                          <td className={td}>
                            {day.tmpsMissVal > 0 ? (
                              <span className={'cell level-4'}>
                                {day.avgTmps}
                              </span>
                            ) : (
                              <span>{day.avgTmps}</span>
                            )}
                          </td>
                        )}

                        {/* mois - Soil Moisture */}
                        {summary.isMois && (
                          <td className={td}>
                            {day.moisMissVal > 0 ? (
                              <span className={'cell level-4'}>
                                {day.avgMois}
                              </span>
                            ) : (
                              <span>{day.avgMois}</span>
                            )}
                          </td>
                        )}

                        {/* tens - Soil Tension */}
                        {summary.isTens && (
                          <td className={td}>
                            {day.moisMissVal > 0 ? (
                              <span className={'cell level-4'}>
                                {day.avgTens}
                              </span>
                            ) : (
                              <span>{day.avgTens}</span>
                            )}
                          </td>
                        )}
                      </tr>
                    )
                  })}

                  {/* Sum or Avg */}
                  <tr className={`text-center bg-secondary-800`}>
                    <td className={tdSummary}>Sum or Avg:</td>
                    {summary.isTemp && (
                      <td className={tdSummary}>{summary.summaryAvgT}</td>
                    )}
                    {summary.isTemp && (
                      <td className={tdSummary}>{summary.summaryMaxTAvg}</td>
                    )}
                    {summary.isTemp && (
                      <td className={tdSummary}>{summary.summaryMinTAvg}</td>
                    )}
                    {summary.isPrcp && (
                      <td className={tdSummary}>{summary.summaryPrcp}</td>
                    )}
                    {summary.isLwet && (
                      <td className={tdSummary}>{summary.summaryLwet}</td>
                    )}
                    {summary.isRhum && (
                      <td className={tdSummary}>{summary.summaryRhum}</td>
                    )}
                    {summary.isSrad && (
                      <td className={tdSummary}>{summary.summarySrad}</td>
                    )}
                    {summary.isWspd && (
                      <td className={tdSummary}>{summary.summaryWspd}</td>
                    )}
                    {summary.isTmps && (
                      <td className={tdSummary}>{summary.summaryTmps}</td>
                    )}
                    {summary.isMois && (
                      <td className={tdSummary}>{summary.summaryMois}</td>
                    )}
                    {summary.isTens && (
                      <td className={tdSummary}>{summary.summaryTens}</td>
                    )}
                  </tr>

                  {/* Max */}
                  <tr className={`text-center bg-secondary-800`}>
                    <td className={tdSummary}>Max:</td>
                    {summary.isTemp && (
                      <td className={tdSummary}>{summary.summaryAvgTMax}</td>
                    )}
                    {summary.isTemp && (
                      <td className={tdSummary}>{summary.summaryMaxT}</td>
                    )}
                    {summary.isTemp && (
                      <td className={tdSummary}>{summary.summaryMinTMax}</td>
                    )}
                    {summary.isPrcp && (
                      <td className={tdSummary}>{summary.summaryPrcpMax}</td>
                    )}
                    {summary.isLwet && (
                      <td className={tdSummary}>{summary.summaryLwetMax}</td>
                    )}
                    {summary.isRhum && (
                      <td className={tdSummary}>{summary.summaryRhumMax}</td>
                    )}
                    {summary.isSrad && (
                      <td className={tdSummary}>{summary.summarySradMax}</td>
                    )}
                    {summary.isWspd && (
                      <td className={tdSummary}>{summary.summaryWspdMax}</td>
                    )}
                    {summary.isTmps && (
                      <td className={tdSummary}>{summary.summaryTmpsMax}</td>
                    )}
                    {summary.isMois && (
                      <td className={tdSummary}>{summary.summaryMoisMax}</td>
                    )}
                    {summary.isTens && (
                      <td className={tdSummary}>{summary.summaryTensMax}</td>
                    )}
                  </tr>

                  {/* Min */}
                  <tr className={`text-center bg-secondary-800`}>
                    <td className={tdSummary}>Min:</td>
                    {summary.isTemp && (
                      <td className={tdSummary}>{summary.summaryAvgTMin}</td>
                    )}
                    {summary.isTemp && (
                      <td className={tdSummary}>{summary.summaryMaxTMin}</td>
                    )}
                    {summary.isTemp && (
                      <td className={tdSummary}>{summary.summaryMinT}</td>
                    )}
                    {summary.isPrcp && (
                      <td className={tdSummary}>{summary.summaryPrcpMin}</td>
                    )}
                    {summary.isLwet && (
                      <td className={tdSummary}>{summary.summaryLwetMin}</td>
                    )}
                    {summary.isRhum && (
                      <td className={tdSummary}>{summary.summaryRhumMin}</td>
                    )}
                    {summary.isSrad && (
                      <td className={tdSummary}>{summary.summarySradMin}</td>
                    )}
                    {summary.isWspd && (
                      <td className={tdSummary}>{summary.summaryWspdMin}</td>
                    )}
                    {summary.isTmps && (
                      <td className={tdSummary}>{summary.summaryTmpsMin}</td>
                    )}
                    {summary.isMois && (
                      <td className={tdSummary}>{summary.summaryMoisMin}</td>
                    )}
                    {summary.isTens && (
                      <td className={tdSummary}>{summary.summaryTensMin}</td>
                    )}
                  </tr>
                </tbody>
              </table>
            </ScrollableFeed>
          </div>
        </div>
      </div>
    </div>
  )
}
